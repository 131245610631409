export default {
	/**
	 * @description 配置显示在浏览器标签的title
	 */
	title: '科技牛官网',
	/**
	 * @description token在Cookie中存储的天数，默认1天
	 */
	cookieExpires: 1,

	/**
	 * @description api请求基础路径
	 */
	baseUrl: {
		dev: 'https://api.keji6.cn/api/',
		pro: 'https://api.keji6.cn/api/'
	},
	qmbaseUrl: {
		dev: 'https://qmapi.keji6.cn/',
		pro: 'https://qmapi.keji6.cn/'
	},
	wsUrl: {
		dev: 'wss://api.keji6.cn/websocket',
		pro: 'wss://api.keji6.cn/websocket',
	},
	/**
	 * @description 默认打开的首页的路由name值，默认为home
	 */
	homeName: 'home',

}
