import { render, staticRenderFns } from "./App.vue?vue&type=template&id=f638e936"
import script from "./App.vue?vue&type=script&lang=js"
export * from "./App.vue?vue&type=script&lang=js"
import style0 from "./App.vue?vue&type=style&index=0&id=f638e936&prod&lang=less"


/* normalize component */
import normalizer from "!../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./App.vue?vue&type=custom&index=0&blockType=link&rel=stylesheet&href=https%3A%2F%2Fcdnjs.cloudflare.com%2Fajax%2Flibs%2Fanimate.css%2F4.1.1%2Fanimate.min.css"
if (typeof block0 === 'function') block0(component)

export default component.exports