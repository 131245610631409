<template>
	<div id="demandList">
		<div class="wrap backgrey">
			<div class="column-search">
				<!-- 面包屑导航-->
				<el-breadcrumb separator-class="el-icon-arrow-right">
					<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
					<el-breadcrumb-item>需求列表</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
			<div class="container">
				<div class="left">
					<div class="task_class_list">
						<div class="cate-item ">
							<div class="cate-item-inner">
								<label class="item-label">需求类型：</label>
								<div :class="trade_active ? 'active' : 'unfold'">
									<ul class="box">
										<li v-for="(c,index) in cate.type" :key="index"
											:class="{checked:index==type_index&&demand_request.demand_type == '' ||demand_request.demand_type==c.name}"
											@click="changeList(1,index,c.name)">{{c.name}}</li>
									</ul>
								</div>

							</div>
						</div>
						<div class="cate-item ">
							<div class="cate-item-inner">
								<label class="item-label">产业领域：</label>
								<div :class="craft_active ? 'active' : 'unfold'">
									<ul class="box">
										<li v-for="(c,index) in industrial" :key="index"
											:class="{checked:index==industry_index}" @click="changeList(2,index,c)">
											{{c}}</li>
									</ul>
								</div>
								<span :class="craft_active ? 'item-down' :'item-span' "
									v-html="craft_active ? '收起' : '展开'" @click="craft_active = !craft_active"></span>
							</div>
						</div>
						<div class="cate-item ">
							<div class="cate-item-inner">
								<label class="item-label">所在地区：</label>
								<div :class="city_active ? 'active' : 'unfold'">
									<ul class="box">
										<li v-for="(c,index) in cate.city" :key="index"
											:class="{checked:index==province_index}"
											@click="changeList(3,index,c.name)">{{c.name}}</li>
									</ul>
								</div>
								<span :class="city_active ? 'item-down' :'item-span' "
									v-html="city_active ? '收起' : '展开'" @click="city_active = !city_active"></span>
							</div>
						</div>

					</div>
					<div class="article_wrap">
						<div class="post-content fn-clear" v-for="(item,key) in demand.list" :key="key"
							@click="demand_href(item.id)">
							<div class="en-on-01" v-if="item.status==0">方案征集中</div>
							<div class="entry-thumbnail">
								<el-image style="width: 300px; height: 230px" :src="item.cover[0]" fit="cover">
								</el-image>
							</div>
							<div class="entry-info">
								<div class="entry-title fn-clear">
									<span>{{item.title}}</span>
								</div>
								<div class="entry-mark fn-clear">
									<ul>
										<li>剩余时间:</li>
										<li>{{item.rest_day}}天</li>
									</ul>
								</div>
								<div class="entry-excerpt fn-clear" v-html="item.describe"> </div>
								<div class="entry-meta fn-clear">
									<div class="entry-tag">
										<span class="label-gray" v-for="(tag,tag_index) in item.tag"
											:key="tag_index"><em><span>{{tag}}</span></em></span>

									</div>
									<div class="share-join">
										<div class="icon-number-box fl">
											<i class="icon-btn-18 icon-comment-18"></i>
											<span class="collectNum">{{item.view_num}}</span>
										</div>
										<div class="icon-number-box fl">
											<i class="icon-btn-18 icon-collect-18"></i>
											<span class="collectNum">{{item.collect_num}}</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="page">
						<el-pagination background @current-change="handleCurrentChange" :current-page="currentPage"
							:page-size="demand_request.limit" layout="total, prev, pager, next, jumper"
							:total="demand.count">
						</el-pagination>
					</div>
				</div>
				<div class="right">
					
				</div>
				<div class="clear"></div>
			</div>
		</div>
	</div>
</template>

<script>

	export default {
		name:'DemandList',
		components: {
		},
		metaInfo: {
			title: '科技牛—为企业精准匹配科研专家以及提供技术解决方案的服务平台',
			meta: [{
					name: 'keywords',
					content: '技术对接,技术交易,技术对接平台,技术创新服务,技术转移,成果转化,技术推广,解决方案'
				},
				{
					name: 'description',
					content: '科技牛是一家专业为企业提供四技服务:技术开发、技术转让、技术咨询、技术服务，精准匹配科研专家及技术解决方案平台公司'
				}
			]
		},
		data() {
			return {
				trade_active: false,
				craft_active: false,
				city_active: false,
				n: 0,
				type_index: 0,
				industry_index: 0,
				province_index: 0,
				currentPage: 1,
				demand_request: {
					limit: 5,
					page: 1,
					status: 1,
					demand_type: '',
					industry: '',
					province: '',
					title: '',
				},
				demand: {
					list: [],
					count: 0,
				},
				cate: [],
				industrial: []
			}
		},
		watch: {
			$route() {
				let query = this.$route.query
				console.log('route', query)
				if (typeof(query.page) != 'undefined') {
					this.demand_request.page = parseInt(query.page);
					this.currentPage = parseInt(query.page);
				} else {
					this.demand_request.page = 1;
					this.currentPage = 1;
				}
				if (typeof(query.keyword) != 'undefined' && query.keyword != '') {
					this.demand_request.title = query.keyword;
				} else {
					this.demand_request.title = '';
				}
				this.get_demand_list();
			},
		},
		created() {
			let query = this.$route.query
			console.log('created', query)
			if (typeof(query.page) != 'undefined') {
				this.demand_request.page = parseInt(query.page);
				this.currentPage = parseInt(query.page);
			} else {
				this.demand_request.page = 1;
				this.currentPage = 1;
			}
			if (typeof(query.keyword) != 'undefined') {
				this.demand_request.title = query.keyword;
			}
		},

		mounted() {
			let query = this.$route.query
			console.log('mounted', query)
			if (typeof(query.demand_type) != 'undefined') {
				this.demand_request.demand_type = query.demand_type
			}
			this.get_demand_list();
			this.get_cate();
			this.expertCate();
		},
		methods: {
			async get_demand_list() {
				await this.$http_qm.post('demand/list', this.demand_request).then(res => {
					this.demand.list = res.data;
					this.demand.count = res.count;
				}).catch(err => {
					console.log(err);
				})
			},
			async expertCate() {
				await this.$http_qm.post('expert/cate', {
					type: 'industrial'
				}).then(res => {
					if (res.code == 200) {
						this.industrial = res.data.industrial;
						this.industrial.unshift('不限')
					}
				}).catch(err => {
					console.log(err)
				})
			},
			async get_cate() {
				await this.$http_qm.post('demand/cate', {}).then(res => {
					this.cate = res.data;
					this.cate.city.unshift({
						name: '不限'
					})
					this.cate.type.unshift({
						name: '不限'
					})
				}).catch(err => {
					console.log(err)
				})
			},
			changeList(cate_index, index, name) {
				if (cate_index == 1) {
					if (name == '不限') {
						this.type_index = 0;
						this.demand_request.demand_type = '';
					} else {
						this.type_index = index;
						this.demand_request.demand_type = name
					}
				} else if (cate_index == 2) {
					if (name == '不限') {
						this.industry_index = 0;
						this.demand_request.industry = ''
					} else {
						this.industry_index = index;
						this.demand_request.industry = name
					}

				} else {
					if (name == '不限') {
						this.province_index = 0;
						this.demand_request.province = ''
					} else {
						this.province_index = index;
						this.demand_request.province = name
					}

				}
				this.demand_request.page = 1;
				this.get_demand_list();

			},
			handleCurrentChange(page) {
				this.$router.push({
					path: '/demand',
					query: {
						page: page
					}
				})
				// document.querySelector('#app').scrollIntoView({
				// 	behavior: "auto"
				// });
				// this.demand_request.page = val;
				// this.get_demand_list();
			},
			demand_href(id) {
				this.$router.push({
					name: 'demandDetail',
					query: {
						id: id
					}
				})
			}
		}
	}
</script>

<style lang="less" scoped>
	.backgrey {
		background: #f4f4f4;
		padding-bottom: 20px;

		.column-search {
			width: 1200px;
			margin: 0 auto;
			height: 60px;
			font: 14px/60px "微软雅黑";
			color: #999;
			display: flex;
			align-items: center;
		}

		.container {
			width: 1200px;
			font: 12px/24px "微软雅黑", sans-serif;
			color: #333;
			margin: 0 auto;

			.left {
				float: left;
				width: 920px;

				.task_class_list {
					border: 1px solid #e9e9e9;
					background: #fff;

					.cate-item {
						margin-top: 10px;
						position: relative;
						overflow: hidden;
						padding: 0 85px 0 85px;

						.cate-item-inner {
							overflow: hidden;

							.item-label {
								position: absolute;
								top: 5px;
								left: 20px;
								font-size: 14px;
								font-weight: 700;
								vertical-align: middle;
							}

							span {
								position: absolute;
								top: 5px;
								right: 20px;
								padding-right: 10px;
								cursor: pointer;
								transition: max-height .25s;
							}

							.item-span::after {
								content: '';
								display: inline-block;
								width: 12px;
								height: 8px;
								margin-left: 2px;
								background: url('https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/other/arrow_icon.png') no-repeat -83px -18px;
							}

							.item-down::after {
								content: '';
								display: inline-block;
								width: 12px;
								height: 8px;
								margin-left: 2px;
								background: url('https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/other/arrow_icon.png') no-repeat -43px -18px;
							}

							.unfold {
								height: 40px !important;
								transition: max-height .25s;
							}

							.active {
								height: auto !important;
								transition: max-height .25s;
							}

							ul {
								padding: 0;
								list-style: none;
								line-height: 20px;

								li {
									padding: 4px 12px;
									display: inline-block;
									border-radius: 8px;
									text-align: center;
									cursor: pointer;
									transition: all 0.3s linear;
									margin: 0 0 8px 15px;
								}

								li:hover {
									color: #0088ff;
								}

								li.checked {
									background: #f0f0f0;
									color: #0088ff;
									padding: 4px 12px;
									border-radius: 12px;
								}
							}
						}
					}
				}

				.article_wrap {
					width: 920px;
					margin: 30px auto 0;

					.post-content {
						background-color: #FFF;
						height: 228px;
						margin-bottom: 25px;
						position: relative;
						display: flex;
						flex-direction: row;
						cursor: pointer;

						.en-on-01 {
							background-image: url(https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/other/entryon1.png);
							width: 119px;
							height: 45px;
							position: absolute;
							right: 20px;
							top: -12px;
							line-height: 45px;
							text-align: center;
							color: #FFF;
							font-size: 18px;
							padding-left: 5px;
							background-repeat: no-repeat;
						}

						.entry-thumbnail {
							float: left;
							width: 312px;
							height: 228px;
							box-sizing: border-box;

							img {
								width: 300px;
								height: 100%;
							}
						}

						.entry-info {
							width: 618px;
							display: flex;
							flex-direction: column;
							padding: 14px 18px 14px 18px;
							color: #000;
							line-height: 25px;

							.entry-title {
								font-size: 20px;
								margin-bottom: 10px;
								font-weight: bold;
								display: block;
								text-overflow: ellipsis;
								overflow: hidden;
								white-space: nowrap;
								width: 490px;
								line-height: 27px;
								height: 27px;
								float: left;
								font-family: Microsoft YaHei, 微软雅黑, 宋体, simsun;
								list-style-type: none;
								text-decoration: none;
								margin-top: 10px;

								span {
									color: #000;
								}
							}

							.entry-mark {
								margin-top: 5px;
								font-size: 16px;
								color: #999;
								margin-bottom: 10px;
								width: 600px;
								display: flex;

								ul {
									float: left;
									margin: 0px;

									li {
										float: left;
										margin-right: 15px;
									}
								}
							}

							.entry-excerpt {
								overflow: hidden;
								height: 78px;
								width: 580px;
								font-size: 16px;
								text-align: justify;
								word-break: break-all;
								display: -webkit-box;
								text-overflow: ellipsis;
								-webkit-line-clamp: 3;
								-webkit-box-orient: vertical;
							}

							.entry-meta {
								margin-top: 15px;
								padding-right: 20px;
								display: flex;
								justify-content: space-between;

								.entry-tag {
									float: left;

									.label-gray {
										float: left;
										font-family: Microsoft YaHei, 微软雅黑, 宋体, simsun;
										color: #999;
										padding: 1px 10px;
										border-radius: 15px;
										-moz-border-radius: 15px;
										-webkit-border-radius: 15px;
										text-decoration: none;
										margin-right: 8px;
										font-size: 12px;
										line-height: 18px;
										height: 20px;
										border: solid 1px #999;

										em {
											font-style: normal;

											span {
												color: #999;
												font-size: 14px;
											}
										}
									}
								}

								.share-join {
									display: flex;
									flex-direction: row;
									margin-top: -5px;

									.icon-number-box {
										width: auto;
										height: auto;
										display: flex;
										align-items: center;
										margin-left: 10px;
										background-repeat: no-repeat;

										.icon-collect-18,
										.icon-comment-18 {
											padding-left: 5px;
											height: 20px;
											width: 20px;
											line-height: 20px;
											margin: 0px;
											float: left;
											display: block;
											background-repeat: no-repeat;
											background-position: center center;
											margin-right: 5px;
											margin-top: 5px;
											background-image: url(https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/other/share.png);
										}

										.icon-collect-18 {
											background-position: -45px -0px !important;
										}

										.icon-comment-18 {
											background-position: -20px 0px !important;
										}

										.collectNum {
											margin-top: 9px;
											float: left;
											color: #666666;
										}
									}
								}
							}
						}
					}

					.post-content:hover {
						-webkit-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
						box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
						-webkit-transform: translate3d(0, -2px, 0);
						transform: translate3d(0, -2px, 0);
						transition: 0.5s;
						-moz-transition: 0.5s;
						-webkit-transition: 0.5s;
						-o-transition: 0.5s;
					}
				}

				.page {
					text-align: center;
				}
			}

			.right {
				float: right;
				width: 260px;

				.superiority {
					border: 1px solid #e9e9e9;
					background: #fff;
					position: relative;
					width: 258px;
					height: 348px;
					z-index: 10;

					span {
						background: url(https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/other/commend.png) 0 -99px no-repeat;
						width: 50px;
						height: 50px;
						display: block;
						position: absolute;
						left: 0;
						top: 0;
					}

					div {
						position: absolute;
						left: 20px;
						top: 15px;
						width: 218px;
						height: 333px;

						h2 {
							font-size: 40px;
							line-height: 60px;
						}

						h3 {
							font-size: 20px;
							line-height: 30px;
							padding-bottom: 15px;
						}

						dl {
							height: 40px;
							font-size: 12px;
							padding-left: 20px;
							margin-top: 10px;
							position: relative;

							dt {
								font-weight: 700;
								line-height: 20px;
								height: 20px;
								overflow: hidden;
							}

							dd {
								color: #999;
								line-height: 20px;
								height: 20px;
								overflow: hidden;
							}

							&::after {
								content: '';
								position: absolute;
								left: 0;
								top: 10px;
								border-left: 40px solid blue;
								border-width: 10px 10px 10px 10px;
								border-style: solid;
								border-color: transparent transparent transparent #0088ff;
							}
						}
					}
				}
			}

			.clear {
				clear: both;
				height: 0;
				overflow: hidden;
				line-height: 0;
				font-size: 0;
				display: block;
			}
		}
	}
</style>
