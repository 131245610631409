import Vue from 'vue'
import Router from 'vue-router'

import demandList from '../view/demand/list.vue' //需求列表
import demandAdd from '../view/demand/add.vue'
import demandAdmin from '../view/demand/admin'

import login from '../view/user/login.vue'
import register from '../view/user/register.vue'

//表单
import formAdmin from '../view/form/admin.vue'

import userEdit from '../view/user/edit.vue'
import Password from '../view/user/password.vue'
import userAdmin from '../view/user/admin.vue'


//成果
import achivementAdd from "../view/achievement/add.vue"
import achivementAdmin from "../view/achievement/admin.vue"
import achivementDetail from "../view/achievement/detail.vue"
import achivementIndex from "../view/achievement/index.vue"
Vue.use(Router)

const router = new Router({
	mode: "history",
	routes: [{
			path: '/',
			name: 'achivementIndex',
			component: achivementIndex,
			meta: {
				keepAlive: true //此组件需要被缓存
			}
		}, {
			path: '/achivement/add',
			name: 'achivementAdd',
			component: achivementAdd,
			meta: {
				keepAlive: false //此组件需要被缓存
			}
		},
		{
			path: '/form_admin',
			name: 'formAdmin',
			component: formAdmin,
			meta: {
				keepAlive: false //此组件需要被缓存
			}
		},
		{
			path: '/achivement/admin',
			name: 'achivementAdmin',
			component: achivementAdmin,
			meta: {
				keepAlive: false //此组件需要被缓存
			}
		}, {
			path: '/achivement/detail',
			name: 'achivementDetail',
			component: achivementDetail,
			meta: {
				keepAlive: true //此组件需要被缓存
			}
		}, {
			path: '/achievement',
			name: 'achivementIndex',
			component: achivementIndex,
			meta: {
				keepAlive: true //此组件需要被缓存
			}
		},


		{
			path: '/demand',
			name: 'demandList',
			component: demandList,
			meta: {
				keepAlive: true //此组件需要被缓存
			}
		},
		{
			path: '/demand/add',
			name: 'demandAdd',
			component: demandAdd,
			meta: {
				keepAlive: false //此组件需要被缓存
			}
		},
		{
			path: '/demand/admin',
			name: 'demandAdmin',
			component: demandAdmin,
			meta: {
				keepAlive: false 
			}
		},
		{
			path: '/login',
			name: 'login',
			component: login,
			meta: {
				keepAlive: true //此组件需要被缓存
			}
		},
		{
			path: '/register',
			name: 'register',
			component: register,
			meta: {
				keepAlive: true //此组件需要被缓存
			}
		},
		{
			path: '/user_center',
			name: 'userEdit',
			component: userEdit,
			meta: {
				keepAlive: false //此组件需要被缓存
			}
		},
		{
			path: '/user_center/password',
			name: 'Password',
			component: Password,
			meta: {
				keepAlive: true //此组件需要被缓存
			}
		},
		{
			path: '/user_admin',
			name: 'userAdmin',
			component: userAdmin,
			meta: {
				keepAlive: true //此组件需要被缓存
			}
		},


	],
})

router.beforeEach((to, from, next) => {
	const token = localStorage.getItem('token');
	if (!token) {

		if (to.path == '/user_center' || to.path == '/company/qy_message') {
			return next('/login')
		}
	}
	if (to.meta.title != null) {
		document.title = to.meta.title
	} else {
		document.title = "科技牛服务_创新资源共享平台"
	}

	next()

})
export default router
