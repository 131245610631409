<template>
	<div id="add">
		<div class="head">
		<el-page-header  @back="goBack" content="需求发布"></el-page-header>
		</div>
		<div class="wrap">
			
			<div class="container">
				<div class="from-title">
					<h1>需求信息</h1>
				</div>
				<el-form ref="form" :rules="rules" label-position="left" :model="form" label-width="100px">
					<el-form-item label="需求名称:" prop="title" :rules="{ required: true, message: '请输入需求名称' }">
						<el-input class="demand-name" v-model="form.title" placeholder="55个字以内"></el-input>
					</el-form-item>
					<el-form-item label="需求描述:" prop="content" :rules="{ required: true, message: '请输入需求描述' }">
						<vue-editor v-model="form.content" @focus="onEditorFocus" @blur="onEditorBlur"
							use-custom-image-handler @image-added="handleImageAdded"
							@selection-change="onSelectionChange" />
					</el-form-item>

					<el-row>
						<el-col :span="7">
							<el-form-item class="relation" prop="name" label="需求联系人:"
								:rules="{ required: true, message: '请填写需求联系人' }">
								<el-input placeholder="请填写联系人" v-model="form.name" style="width: 200px;">
								</el-input>
							</el-form-item>
						</el-col>
						<el-col class="line" :span="2">手机：</el-col>
						<el-col :span="7">
							<el-form-item class="relation" prop="tel" label="联系人手机:"
								:rules="[{ required: true, message: '请填写联系人手机' }, { min: 11, max: 11, message: '请输入正确的手机号' }]">
								<el-input placeholder="请填写手机号 " type="number" v-model="form.tel"
									style="width: 200px;">
								</el-input>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="7">
							<el-form-item class="relation" prop="company_name" label="企业名称:"
								:rules="{ required: true, message: '请填写企业名称' }">
								<el-input placeholder="请填写企业名称" v-model="form.company_name" style="width: 200px;">
								</el-input>
							</el-form-item>
						</el-col>


					</el-row>
					<el-form-item>
						<el-button :loading="submit_loading" class="submit" type="primary" @click="onSubmit">提交
						</el-button>
					</el-form-item>
				</el-form>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		VueEditor
	} from 'vue2-editor';

	export default {
		name:'DemandAdd',
		metaInfo: {
			title: '科技牛—为企业精准匹配科研专家以及提供技术解决方案的服务平台',
			meta: [{
					name: 'keywords',
					content: '技术对接,技术交易,技术对接平台,技术创新服务,技术转移,成果转化,技术推广,解决方案'
				},
				{
					name: 'description',
					content: '科技牛是一家专业为企业提供四技服务:技术开发、技术转让、技术咨询、技术服务，精准匹配科研专家及技术解决方案平台公司'
				}
			]
		},
		components: {
			VueEditor,

		},
		data() {
			return {
				content: '', //vue2-editor
				props: {
					multiple: true
				}, //行业开启多选
				valueDate: '',
				imageUrl: '',
				upload_data: {
					dir: 'demand'
				},
				submit_loading: false,
				dialogImageUrl: '',
				dialogVisible: false,
				form: {
					title: '',
					content: '',
					name: '',
					tel: '',
					company_name:'',
				},
				cover_list: [],
				rules: {
					title: [{
						required: true,
						message: '请输入需求名称',
						trigger: 'blur'
					}],
					content: [{
						required: true,
						message: '请输入需求名称',
						trigger: 'blur'
					}]
				},
				inputVisible: false,
				inputValue: '',
				industry: '',
				tag: [],
				industry_cate: [],
				mianyi_checked: false,
				city: {
					sf: [],
				},
				province_key: 0,
				custom_deadline: '',
				id: '',
				cate: []
			};
		},
		mounted() {
			const token = localStorage.getItem('token');
			if (!token) {
				this.$router.push({
					name: 'login'
				});
			}

		},
		methods: {
			handleImageAdded(file, Editor, cursorLocation, resetUploader) {
				console.log(file);
				var formData = new FormData();
				formData.append('file', file);
				formData.append('dir', 'demand');
				this.$http
					.post('file/upload_one', formData, {
						headers: {
							'Content-Type': 'multipart/form-data;'
						}
					})
					.then(res => {
						console.log(res);
						let url = res.data.img_url; // Get url from response
						Editor.insertEmbed(cursorLocation, 'image', url);
						resetUploader();
					})
					.catch(function(error) {
						console.log(error);
					});
			},
		


			industryClose(tag) {
				this.form.industry.splice(this.form.industry.indexOf(tag), 1);
			},
			budget_amount_change(v) {
				if (v) {
					this.form.budget_amount = 0;
				}
			},
			get_detail() {
				this.$http_qm
					.post('demand/detail', {
						id: this.id
					})
					.then(res => {
						if (res.code == 200) {
							this.form = res.data;
							let cover_list = this.cover_list;
							this.form.cover.forEach(function(v) {
								cover_list.push({
									url: v
								});
							});
						}
					})
					.catch(err => {
						console.log(err);
					});
			},

			handleAvatarSuccess(res, file) {
				if (res.code == 200) {
					this.imageUrl = URL.createObjectURL(file.raw);
					this.form.cover.push(res.data.img_url);
				}

			},

			onSubmit() {
				this.submit_loading = true;
				this.$refs['form'].validate(valid => {
					if (!valid) {
						console.log('error submit!!');
						this.submit_loading = false;
						return false;
					}

					this.$http_qm
						.post('form/add', {info:this.form,type:9})
						.then(res => {
							if (res.code == 200) {
								this.$router.back();
							} else {
								this.$message.error(res.msg);
							}
							this.submit_loading = false;
						})
						.catch(err => {
							console.log(err);
						});
				});
			},
			goBack() {
				// this.$router.go(-1);
				this.$router.push({path:'/'})
			},
			province_change(event) {
				var cityList = this.city.sf;
				let that = this;
				cityList.forEach(function(item, index) {
					if (event == item.name) {
						that.province_key = index
					}
				})
				// this.form.province = this.city.sf[this.province_key].name;
				this.form.city = '';
				this.$forceUpdate();
			},
			get_industry() {
				this.$http_qm
					.post('expert/cate', {
						type: 'industrial'
					})
					.then(res => {
						this.industry_cate = res.data.industrial;
						this.industry_cate.forEach(function(item) {
							item.checked = false;
						});
					})
					.catch(err => {
						console.log(err);
					});
			},
			add_industry() {
				let industrys = this.form.industry;
				let index = industrys.indexOf(this.industry);
				if (index == -1) {
					industrys.push(this.industry);
				}
				this.$forceUpdate();
			},

			onEditorBlur(quill) {
				console.log('editor blur!', quill);
			},
			onEditorFocus(quill) {
				console.log('editor focus!', quill);
			},
			onSelectionChange(range) {
				console.log('selection change!', range);
			},
			handleClose(tag) {
				this.form.tag.splice(this.form.tag.indexOf(tag), 1);
			},
			showInput() {
				this.inputVisible = true;
				this.$nextTick(() => {
					this.$refs.saveTagInput.$refs.input.focus();
				});
			},

			handleInputConfirm() {
				console.log('handleInputConfirm');
				let inputValue = this.inputValue;
				if (inputValue) {
					this.form.tag.push(inputValue);
				}
				this.inputVisible = false;
				this.inputValue = '';
			}
		}
	};
</script>

<style lang="less" src="../../assets/css/patter.less" />
