<template>
	<div id="achievementList">
		<div class="top">
			<div class="row1">
				<div class="logo">
					<img style="width: 82px;
height: 68px;" src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/app/logo142x117.png">
				</div>
				<div class="col1">
					<input type="text" v-model="achievemnet_request.keyword">
					<div class="col2">
						<div class="search_icon" @click="do_search()">搜索</div>
						或
						<router-link :to="{path:'/demand/add'}" class="add_demand_icon">发布需求</router-link>
					</div>
				</div>
				<template v-if="token">
					<div class="col3">
						<router-link :to="{path:'/user_center'}"> 用户中心</router-link>
						/
						<span @click="logout">退出</span>
					</div>

				</template>
				<template v-else>
					<div class="col3">
						<router-link :to="{path:'/login'}"> 登录</router-link> /
						<router-link :to="{path:'/register'}"> 注册</router-link>
					</div>
				</template>
			</div>
		</div>

		<div class="container">



			<!-- 搜索条件框框位-->

			<div class="search-listbox search-margin">
				<div class="sslist-ylxs" v-for="(v,k) in classify" :key="k">
					<strong>{{v.title}}：</strong>
					<div class="sslist-ssnr">
						<el-radio-group v-model="radio[k]" size="small" @change="tagChange">
							<el-radio-button :label="s" :value="s" v-for="(s,y) in v.tags" :key="y"></el-radio-button>
						</el-radio-group>
					</div>
				</div>
			</div>
			<div class="clearfix">
				<div class="main">
					<div class="well-gray">共{{achievemnet.count}}条数据</div>
					<!-- 列表-->
					<ul class="main-list">

						<li v-for="(l,u) in achievemnet.list" :key="u" @click="todetail(l.id)">
							<div class="a">
								<div class="jx-img fl">
									<el-image v-if="Array.isArray(l.img)" style="width: 150px; height: 100px"
										:src="l.img[0]" fit="cover"></el-image>
									<el-image v-else style="width: 150px; height: 100px" :src="l.img" fit="cover">
									</el-image>
								</div>
								<div class="fr">
									<h4>{{l.title}}
									<span v-if="l.is_recommend" class="jingxuan-icon">精选</span>
									</h4>
									<div class="attribute">
										<span class="width-40">
											<i class="text-gray-light">技术成熟度：</i>应用市场
										</span>
										<div class="width-40 flex">
											<span class="text-gray-light">合作方式：</span>
											<span v-for="(v,k) in l.cooperation_type" :key="k">{{v}} &nbsp;</span>
										</div>
										<span class="width-20 text-right">
											<i class="text-gray-light">价格：</i>
											<i class="text-orange">{{l.reference_price}}</i>
										</span>
									</div>
									<div class="flex space-between">
										<div class="text-gray-light brief">{{l.describe}}</div>
										<div class="text-gray-light fr ml-20">
											<i class="el-icon-location-information"></i>{{l.country}}
										</div>
									</div>
								</div>
							</div>
						</li>

					</ul>
					<!-- 分页-->
					<!-- 分页-->
					<div class="page">
						<el-pagination @current-change="handleCurrentChange" layout="prev, pager, next"
							:total="achievemnet.count" background :page-size="achievemnet_request.limit"
							:current-page="currentPage"></el-pagination>
					</div>
				</div>
				<!-- <div class="sidebar">
				
					<div class="advertisement">
						<div class="a" @click="web_href('https://www.keji6.cn/soar')">
							<img src="https://as.zbjimg.com/static/java-kjfw-www2-web/images/ggw/gqzd.jpg"
								alt="高企诊断,免费测评企业是否符合认定">
						</div>
						<div class="a">
							<img src="https://as.zbjimg.com/static/java-kjfw-www2-web/images/ggw/rzsq.jpg"
								alt="软著申请,保护所有权，防止被抄袭">
						</div>
					</div>
				</div> -->
			</div>
		</div>

	</div>
</template>

<script>
	import {
		mapGetters,
		mapMutations
	} from 'vuex';
	export default {
		name:'AchievementIndex',
		components: {},
		metaInfo: {
			title: '科技牛—为企业精准匹配科研专家以及提供技术解决方案的服务平台',
			meta: [{
					name: 'keywords',
					content: '技术对接,技术交易,技术对接平台,技术创新服务,技术转移,成果转化,技术推广,解决方案'
				},
				{
					name: 'description',
					content: '科技牛是一家专业为企业提供四技服务:技术开发、技术转让、技术咨询、技术服务，精准匹配科研专家及技术解决方案平台公司'
				}
			]
		},
		computed: {
			...mapGetters(['token', 'user'])
		},
		data() {
			return {
				radio: ['全部', '全部', '全部', '全部'],
				classify: [{
						title: '领域分类',
						tags: ['全部']
					},
					{
						title: '合作方式',
						tags: ['全部']
					},
					{
						title: '成熟度',
						tags: ['全部']
					},
					{
						title: '国家',
						tags: ['全部']
					},
				],

				achievemnet_request: {
					limit: 10,
					page: 1,
					cooperation_type: '', //合作方式
					industry: '',
					maturity: '', //成熟度
					keyword: '',
				},
				currentPage: 1,
				achievemnet: {
					list: [],
					count: 0,
				},
			}
		},
		watch: {
			$route() {
				let query = this.$route.query
				if (typeof(query.page) != 'undefined') {
					this.achievemnet_request.page = query.page
					this.currentPage = parseInt(query.page)
				} else {
					this.achievemnet_request.page = 1
					this.currentPage = 1
				}
				if (typeof(query.keyword) != 'undefined') {
					this.achievemnet_request.keyword = query.keyword
					this.currentPage = 1
				}
				this.list();
			},
		},
		created() {
			let query = this.$route.query
			if (typeof(query.page) != 'undefined') {
				this.achievemnet_request.page = parseInt(query.page)
				this.currentPage = parseInt(query.page)
			} else {
				this.achievemnet_request.page = 1
				this.currentPage = 1
			}
			if (typeof(query.keyword) != 'undefined') {
				this.achievemnet_request.keyword = query.keyword
				this.currentPage = 1
			}
		},
		mounted() {
			console.log(this.token);
			console.log(this.user);
			this.get_cate();
			this.list();
		},
		methods: {
			...mapMutations(['setLogout']),
			web_href(url){
				window.open(url)
			},
			logout() {
				this.setLogout();
				this.$router.push({
					path: '/login'
				})
			},
			do_search() {
				this.achievemnet_request.page = 1;
				this.list();
			},
			get_cate() {
				this.$http_qm.post('achievement2/cate', {}).then(res => {
					this.classify[0].tags = this.classify[0].tags.concat(res.data.industry);
					this.classify[1].tags = this.classify[1].tags.concat(res.data.cooperation_type);
					this.classify[2].tags = this.classify[2].tags.concat(res.data.maturity);
					this.classify[3].tags = this.classify[3].tags.concat(res.data.country);
				}).catch(err => {
					console.log(err)
				})
			},
			async list() {
				this.achievemnet_request.page = parseInt(this.achievemnet_request.page)
				await this.$http_qm.post('achievement2/list', this.achievemnet_request).then(res => {
					this.achievemnet.list = res.data;
					this.achievemnet.count = res.count;
				}).catch(err => {
					console.log(err);
				})
			},
			tagChange() {

				if (this.radio[0] == '全部') {
					this.achievemnet_request.industry = ''
				} else {
					this.achievemnet_request.industry = this.radio[0]
				}
				if (this.radio[1] == '全部') {
					this.achievemnet_request.cooperation_type = ''
				} else {
					this.achievemnet_request.cooperation_type = this.radio[1]
				}
				if (this.radio[2] == '全部') {
					this.achievemnet_request.maturity = ''
				} else {
					this.achievemnet_request.maturity = this.radio[2]
				}
				if (this.radio[3] == '全部') {
					this.achievemnet_request.country = ''
				} else {
					this.achievemnet_request.country = this.radio[3]
				}
				this.achievemnet_request.page = 1
				this.list();
			},
			handleCurrentChange(page) {
				this.$router.push({
					path: '/achievement',
					query: {
						page: parseInt(page)
					}
				})
				/* this.achievemnet_request.page = page
				this.list();
				document.querySelector('#app').scrollIntoView({
					behavior: "auto"
				}); */
			},
			todetail(id) {
				this.$router.push({
					path: '/achivement/detail',
					query: {
						id: id
					}
				})
			}
		}
	}
</script>

<style lang="less" src="../../assets/css/achievement.less">
