<template>
	<div class="user_admin">
		<div class="head">
		<el-page-header @back="goBack" content="用户管理">
		</el-page-header>
		</div>
		<div class="box">
			<div class="fl">
				<Left></Left>
			</div>
			<div class="fr">
				<div class="flex flex-column">
					<div class="search_form flex">
						搜索 &nbsp; <el-input v-model="param.keyword" placeholder="请输入内容"></el-input>
						创建时间:&nbsp;

						<el-date-picker v-model="select_time" type="datetimerange" range-separator="至"
							start-placeholder="开始日期" end-placeholder="结束日期">
						</el-date-picker>

						<el-button type="primary" @click="do_search()">查询</el-button>
						<el-button type="primary">
							<download-excel class="btn btn-default" :data="list"
								:fields="excel_title" worksheet="My Worksheet" type="csv"
								name="filename.xls">
							
								导出excel
							
							</download-excel>
							</el-button>

					</div>
					<template>
						<el-table ref="multipleTable" :data="list" @selection-change="handleSelectionChange" stripe
							style="width: 100%">
							<el-table-column type="selection" width="55">
							</el-table-column>
							<el-table-column prop="username" label="用户名" width="180">
							</el-table-column>
							<el-table-column prop="tel" label="用户电话" width="180">
							</el-table-column>
							<el-table-column prop="created_at" label="创建时间">
							</el-table-column>
							<el-table-column label="操作" width="180">
								<template slot-scope="scope">
									<div class="flex space-around">
										<el-button size="mini" @click="handleEdit(scope.$index, scope.row)">编辑
										</el-button>
										<el-popconfirm title="这是一段内容确定删除吗？" @confirm="handleDelete(scope.$index, scope.row)">
											<el-button slot="reference" size="mini" type="danger"
												>
												删除</el-button>
										</el-popconfirm>
									</div>
								</template>
							</el-table-column>
						</el-table>
					</template>
					<el-dialog title="编辑用户信息" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">

						<el-form ref="form" :model="edit_form" label-width="80px">
							<el-form-item label="用户名">
								<el-input v-model="edit_form.username"></el-input>
							</el-form-item>
							<el-form-item label="手机号">
								<el-input v-model="edit_form.tel"></el-input>
							</el-form-item>
							<el-form-item label="密码">
								<el-input v-model="edit_form.password"></el-input>
							</el-form-item>
						</el-form>

						<span slot="footer" class="dialog-footer">
							<el-button @click="dialogVisible = false">取 消</el-button>
							<el-button type="primary" @click="edit_submit()">确 定</el-button>
						</span>
					</el-dialog>
					<el-pagination background layout="prev, pager, next" :page-size="param.limit" :total="this.count">
					</el-pagination>
					<div class="action">
						<el-button @click="del()">删除</el-button>
						<el-button @click="toggleSelection()">取消选择</el-button>
					</div>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
	import Left from "../user/Left.vue"
	export default {
		name:'UserAdmin',
		components: {
			Left
		},
		data() {
			return {
				select_time: [new Date(2021, 8, 0, 0, 0), new Date()],
				param: {
					source_type: 2,
					page: 1,
					limit: 8,
					keyword: '',
					create_time: []
				},
				edit_form: {
					username: '',
					tel: '',
					password: '',
				},
				count: 0,
				list: [],
				dialogVisible: false,
				multipleSelection: [],
				excel_title: {
					'用户名': "username",
					'用户电话': 'tel',
					'创建时间': 'created_at',
				},
			}
		},
		mounted() {
			this.get_list();
		},
		methods: {
			edit_submit() {
				this.$http.post('user/edit', this.edit_form).then(res => {
					if (res.code != 200) {
						this.$message.error(res.msg);
					}else{
						this.$message.success(res.msg);
					}
						this.dialogVisible = false
				}).catch(err => {
					console.log(err);
				})
			},
			handleClose(done) {
				this.$confirm('确认关闭？')
					.then(res => {
						console.log(res)
						done();
					})
					.catch(err => {
						console.log(err)
					});
			},
			handleEdit(index, row) {
				console.log(index, row);
				this.dialogVisible = true
				this.edit_form.username = row.username;
				this.edit_form.tel = row.tel;
			},
			handleDelete(index, row) {
				this.$http_qm.post('user/del', {
					id: row.id,
				}).then(res => {
					if (res.code == 200) {
						this.get_list();
						this.$message({
							type: 'success',
							message: '删除成功!'
						});
					}
				}).catch(err => {
					console.log(err);
				})
			},
			del() {
				let that = this;
				let ids = []
				console.log(this.multipleSelection);
				this.multipleSelection.forEach(function(v) {
					ids.push(v.id)
				})
				this.$confirm('确定删除?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$http_qm.post('user/del', {
						ids: ids,
					}).then(res => {
						if (res.code == 200) {
							that.get_list();
							that.$message({
								type: 'success',
								message: '删除成功!'
							});
						}
					}).catch(err => {
						console.log(err);
					})

				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
			},
			do_search() {
				this.param.page = 1;
				this.get_list();
			},
			toggleSelection(rows) {
				if (rows) {
					rows.forEach(row => {
						this.$refs.multipleTable.toggleRowSelection(row);
					});
				} else {
					this.$refs.multipleTable.clearSelection();
				}
			},
			handleSelectionChange(v) {
				this.multipleSelection = v
			},
			goBack() {
				// this.$router.back();
				this.$router.push({path:'/'})
			},
			get_list() {
				this.param.create_time[0] = this.select_time[0].getTime().toString();
				this.param.create_time[1] = this.select_time[1].getTime().toString();
				this.$http_qm.post('user/list', this.param).then(res => {
					this.list = res.data;
					this.count = res.count;
				}).catch(err => {
					console.log(err)
				})
			},
		}
	}
</script>

<style lang="less">
	.user_admin {
		background: #f5f5f5;
		display: flex;
		flex-direction: column;
		height: 100vh;

		.box {

			width: 1200px;
			margin: 26px auto 0px;
			display: flex;

			.fl {
				width: 160px;
				background: #fff;
				text-align: center;
			}

			.fr {
				background: #fff;
				float: right;
				flex: 1;
				margin-left: 20px;
				.search_form {
					margin: 20px 10px;
					display: flex;
					align-items: center;

					.el-input {
						width: 200px;
						margin: 0px 10px;
					}

					.el-date-editor {
						margin: 0px 10px;
					}

					button {}
				}

				.action {
					margin: 20px 10px;
				}
			}
		}
	}
</style>
