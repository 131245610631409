<template>
	<div id="qy_column">
		<el-tree :data="user.user_type==6 ? data : data1" node-key="id" :default-expanded-keys="[1,2, 3,4,5,6,7,8,9]"
			:props="defaultProps" @node-click="handleNodeClick"></el-tree>
	</div>
</template>

<script>
	import {mapGetters} from 'vuex'
	export default {
		name:'UserLeft',
		computed: {
			...mapGetters(['user'])
		},
		data() {
			return {
				data: [{
						id: 1,
						label: '账号管理',
						children: [{
							label: '基本信息',
							tourl: 'userEdit'
						}, {
							label: '账号密码',
							tourl: 'Password'
						}]
					}, {
						id: 2,
						label: '成果管理',
						disabled: true,
						children: [ {
							label: '成果管理',
							tourl: 'achivementAdmin'
						},{
							label: '成果发布',
							tourl: 'achivementAdd'
						}]
					}, {
						id:3,
						label: '需求管理',
						disabled: true,
						children: [{
							label: '需求发布',
							tourl: 'demandAdd'
						}]
					}, {
						id: 4,
						label: '用户管理',
						disabled: true,
						children: [{
							label: '用户管理',
							tourl: 'userAdmin'
						}]
					}, {
						id:5,
						label: '表单管理',
						disabled: true,
						children: [{
							label: '咨询管理',
							tourl: 'formAdmin'
						}, {
							label: '需求管理',
							tourl: 'demandAdmin'
						}]
					}
				],
				data1: [{
						id: 1,
						label: '账号管理',
						children: [{
							label: '基本信息',
							tourl: 'userEdit'
						}, {
							label: '账号密码',
							tourl: 'Password'
						}]
					},
					{
						id: 2,
						label: '需求管理',
						disabled: true,
						children: [{
							label: '需求发布',
							tourl: 'demandAdd'
						}, {
							label: '需求管理',
							tourl: 'demandAdmin'
						}]
					}
				],
				defaultProps: {
					children: 'children',
					label: 'label'
				}
			}
		},
		created() {
			console.log(this.user)
			this.get_info();
		},
		methods: {
			handleNodeClick(data) {
				if (data.tourl != "" && data.tourl != undefined) {
					this.$router.push({
						name: data.tourl
					})
				}
			},
			get_info(){
			
			},
		}
	}
</script>

<style lang="less">
	.el-tree {
		color: #333 !important;

		.el-tree-node__content {
			height: 35px !important;
		}
	}
</style>
