import Vue from 'vue'
import App from './App.vue'

import router from './router/index.js'
router.afterEach(() => {
	window.scrollTo(0, 0)
})

import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

import JsonExcel from 'vue-json-excel'
Vue.component('downloadExcel', JsonExcel)

import {
	http,
	httpqm,
	baseUrl,
	baseUrlqm
} from './api/index'

Vue.prototype.$http_qm = httpqm
Vue.prototype.$http = http
Vue.prototype.baseUrl = baseUrl
Vue.prototype.baseUrlqm = baseUrlqm

Vue.config.productionTip = false

import MetaInfo from 'vue-meta';
Vue.use(MetaInfo);

new Vue({
	router,
	store,
	render: h => h(App),
	mounted() {
		document.dispatchEvent(new Event('render-event'))
	}
}).$mount('#app')
