<template>
	<div id="admin">
		<div class="head">
			<el-page-header @back="goBack" content="需求管理">
			</el-page-header>
		</div>
		<div class="wrapper">
			<div class="main_left">
				<Left></Left>
			</div>
			<div class="main_right transition-box">
				<transition name="el-zoom-in-center">
					<div class="page" v-if="show">
						<div class="page_nav">
							<div class="page_nav_tit">需求管理</div>
							<!-- <div class="page_nav_tit_desc">
							您已发布的产品总数为： <span class="highlight">7</span> 条
						</div> -->
						</div>
						<div class="page_list">


							<div class="listbox">

								<el-form :inline="true" :model="formInline" class="demo-form-inline">

									<el-form-item label="搜索:">
										<el-input size="small" v-model="form.post.keyword" placeholder="请输入关键词">
										</el-input>
									</el-form-item>
									<el-form-item label="发布时间">
										<el-date-picker v-model="create_time" type="datetimerange" range-separator="至"
											start-placeholder="开始日期" end-placeholder="结束日期">
										</el-date-picker>
									</el-form-item>
									<el-form-item>
										<el-button size="small" type="primary" @click="onSubmit">查询</el-button>
									</el-form-item>
									<el-form-item>
										<el-button size="small" type="primary" @click="onSubmit">
											<download-excel class="btn btn-default" :data="form.list"
												:fields="excel_title" worksheet="My Worksheet" type="csv"
												name="filename.xls">

												导出excel

											</download-excel>
										</el-button>
									</el-form-item>

								</el-form>

								<el-table ref="multipleTable" :data="form.list" tooltip-effect="dark"
									style="width: 100%" @selection-change="handleSelectionChange">
									<el-table-column type="selection" width="55"></el-table-column>

									<el-table-column label="需求信息" show-overflow-tooltip>
										<template slot-scope="scope">
											<span class="product_title">{{scope.row.info.title}}</span>

										</template>
									</el-table-column>
									<el-table-column align="center" label="联系人">
										<template slot-scope="scope">
											{{scope.row.info.name}}
										</template>
									</el-table-column>
									<el-table-column align="center" label="联系电话">
										<template slot-scope="scope">
											{{scope.row.info.tel}}
										</template>
									</el-table-column>
									<el-table-column align="center" label="提交时间">
										<template slot-scope="scope">
											{{scope.row.created_at}}
										</template>
									</el-table-column>

									<el-table-column align="center" class="action" width="120" label="操作">
										<template slot-scope="scope">
											<div class="flex space-around">
												<span class="show" @click="show(scope.$index)">
													查看
												</span>
												<span class="del"
													@click="handleDelete(scope.$index, scope.row.id)">删除</span>
											</div>
										</template>

									</el-table-column>
								</el-table>
								<div class="col1" style="margin-top: 20px;">
									<el-button size="small" @click="toggleDel()">删除</el-button>
									<el-button size="small" @click="toggleSelection()">取消选择</el-button>
								</div>
								<div class="product_page">
									<el-pagination @current-change="handleCurrentChange" :page-size="form.post.limit"
										layout="total, prev, pager, next" :total="form.count">
									</el-pagination>
								</div>
							</div>


						</div>
					</div>
				</transition>
			</div>
		</div>
		<el-drawer v-if="form.list.length>0" class="" title="需求详情" :visible.sync="drawer" :direction="direction"
			:before-close="handleClose">
			<div class="drawer_ul">
				<div class="li">
					需求名称：{{form.list[this.show_index].info.title}}
				</div>
				<div class="li">
					需求内容： <p v-html="form.list[this.show_index].info.content"></p>
				</div>
				<div class="li">
					联 系 人 ：{{form.list[this.show_index].info.name}}
				</div>
				<div class="li">
					联系电话：{{form.list[this.show_index].info.tel}}
				</div>
				<div class="li">
					企业名称：{{form.list[this.show_index].info.company_name}}
				</div>
				<div class="li">
					提交时间：{{form.list[this.show_index].created_at}}
				</div>
			</div>
		</el-drawer>
	</div>
</template>

<script>
	import Left from "../user/Left.vue"

	import {
		mapGetters
	} from 'vuex'
	export default {
		name:'DemandAdmin',
		metaInfo: {
			title: '科技牛—为企业精准匹配科研专家以及提供技术解决方案的服务平台',
			meta: [{
					name: 'keywords',
					content: '技术对接,技术交易,技术对接平台,技术创新服务,技术转移,成果转化,技术推广,解决方案'
				},
				{
					name: 'description',
					content: '科技牛是一家专业为企业提供四技服务:技术开发、技术转让、技术咨询、技术服务，精准匹配科研专家及技术解决方案平台公司'
				}
			]
		},
		components: {
			Left,

		},
		data() {
			return {

				drawer: false,
				direction: 'rtl',
				excel_title: {
					'标题': "info.title",
					'联系人': 'info.name',
					'联系电话': 'info.tel',
					'发布日期': 'info.created_at',
				},
				show_index: 0,
				formInline: {
					user: '',
					region: ''
				},
				multipleSelection: [],
				currentPage1: 1,
				create_time: [new Date(2021, 8, 0, 0, 0), new Date()],
				form: {
					post: {
						page: 1,
						limit: 8,
						keyword: '',
						status: 9,
						uid: '',
						type: 9,
						create_time: [],
					},
					list: [],
					count: 0,
				},
				tab_index: 0,
				activeName: 'item1',
				tabItem: [{
					label: '全部',
					name: 'item1',
					status: 9,
				}, {
					label: '其它',
					name: 'item2',
					status: 1,
				}],

			}
		},
		mounted() {
			this.form.post.uid = this.user.id;
			this.get_list()
		},
		computed: {
			...mapGetters(['user'])
		},
		methods: {
			goBack() {
				// this.$router.back();
				this.$router.push({
					path: '/'
				})
			},
			show(index) {
				this.drawer = true;
				this.show_index = index
			},
			handleClose(done) {
				done();
				// this.$confirm('确认关闭？')
				// 	.then(res => {
				// 		console.log(res)

				// 	})
				// 	.catch(err => {
				// 		console.log(err)
				// 	});
			},

			toggleDel() {
				let that = this;
				let ids = []
				this.multipleSelection.forEach(function(v) {
					ids.push(v.id)
				})
				this.$confirm('确定删除?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$http_qm.post('form/del', {
						ids: ids,
					}).then(res => {
						if (res.code == 200) {
							that.get_list();
							that.$message({
								type: 'success',
								message: '删除成功!'
							});
						}
					}).catch(err => {
						console.log(err);
					})

				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});


			},
			get_list() {
				this.form.post.create_time = [this.create_time[0].getTime().toString(),
					this.create_time[1].getTime().toString()
				]
				this.$http_qm.post('form/list', this.form.post).then(res => {
					if (res.code == 200) {
						if (res.data == null) {
							this.form.list = []
							this.form.count = 0;
						} else {
							this.form.list = res.data
							this.form.count = res.count;
						}

					}
				}).catch(err => {
					console.log(err)
				})
			},
			handleCurrentChange(val) {
				this.form.post.page = val;
				this.get_list();
			},
			toggleSelection() {
				// this.$refs.multipleTable.clearSelection();
				this.$nextTick(() => {
					this.$refs.multipleTable.clearSelection()
				})
			},
			handleSelectionChange(val) {
				this.multipleSelection = val;
			},
			handleEdit(index, id) {
				this.$router.push({
					path: '/form/add',
					query: {
						id: id
					}
				})
			},
			handleAedit(index, id) {
				console.log(index, id);
				this.update(id, 'reset');
			},

			// 删除c产品
			handleDelete(index, id) {

				this.$confirm('确定删除?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$http_qm.post('form/del', {
						id: id,
					}).then(res => {
						if (res.code == 200) {
							this.form.list.splice(index, 1);
							this.$message({
								type: 'success',
								message: '删除成功!'
							});
						}
					}).catch(err => {
						console.log(err);
					})

				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});


			},
			handleChech(id) {
				this.show = false
				this.scheme.post.form_id = id
				this.scheme_list()
				document.querySelector('#app').scrollIntoView({
					behavior: 'auto'
				})
			},

			schemeChange(val) {
				this.scheme.post.page = val
				this.scheme_list()
			},
			needClick(tab) {
				console.log(tab.$attrs);
				// this.activity_post.status = tab.$attrs.status;
				// this.get_list();
			},
			onSubmit() {
				this.get_list()
			},
			handleClick(tab) {
				this.tab_index = tab.index
				this.form.post.status = tab.$attrs.status;
				this.get_list();
			}
		}
	}
</script>

<style lang="less">
	.flex {
		display: flex;
	}


	.space-between {
		justify-content: space-between;
	}

	.space-around {
		justify-content: space-around;
	}

	#admin {
		margin: 0;
		padding: 0;
		background: #f5f5f5;
		display: flex;
		flex-direction: column;

		.drawer_ul {
			padding: 0px 36px;

			.li {
				margin-bottom: 23px;
			}
		}

		.wrapper {
		
			width: 1200px;
			margin: 26px auto 0px;
			flex-direction: row;
			justify-content: space-between;
			display: flex;

			.main_left {
				width: 160px;
				height: auto;
				text-align: center;
			}

			.transition-box {
				margin-bottom: 10px;
				width: 200px;
				height: 100px;
				border-radius: 4px;
				background-color: #409EFF;
				padding: 40px 20px;
				box-sizing: border-box;
			}

			.main_right {
				flex: 1;
				margin-left: 20px;
				height: auto;
				min-height: 750px;
				background: #fff;

				padding: 15px;

				.page {
					padding: 0;

					.page_nav {
						padding: 0 15px;
						display: flex;
						border-bottom: 3px #0088ff solid;

						.page_nav_tit {
							float: left;
							font-size: 18px;
							font-family: "微软雅黑";
							cursor: pointer;
							margin-right: 20px;
						}

						.page_nav_tit_desc {
							float: left;
							line-height: 16px;
							font-size: 12px;
							margin: 7px 0 0 0;
						}
					}

					.page_list {
						margin-top: 10px;

						.listbox {
							text-align: left;

							.show {
								color: #4180FF;
								font-size: 14px;
								cursor: pointer;
							}

							.del {
								cursor: pointer;
								color: #FFB43F;
								font-size: 14px;
							}

							.el-form--inline {
								display: flex;
								align-items: center;

								.col1 {
									margin-right: 10px;
								}
							}

							.el-form-item {
								margin-bottom: 0px;
							}

							td {
								border: none;
							}

							.product_title {
								font-size: 12px;
								width: 123px;
								height: 25px;
								color: #2a5db0;
								display: block;
								cursor: pointer;
							}

							.product_title:hover {
								color: #db4f33;
								text-decoration: underline;
							}

							.product_classes {
								font-size: 12px;
								display: block;

								b {
									color: #2a5db0;
									cursor: pointer;
								}

								b:hover {
									color: #db4f33;
									text-decoration: underline;
								}
							}

							.product_data {
								font-size: 12px;
								display: block;
							}

							.product_page {
								text-align: center;
								padding: 10px 0 30px;
							}
						}
					}
				}

				.schemePage {
					padding: 0;

					.demo-table-expand {
						font-size: 0;
					}

					label {
						width: 90px;
						color: #99a9bf;
					}

					.el-form-item {
						margin-right: 0;
						margin-bottom: 0;
						width: 50%;
					}

					td {
						border: none;
					}

					th {
						background: #f5f7fa;
					}

					.user-event-admin-empty {
						padding-top: 40px;
						text-align: center;
						height: 500px;
						position: relative;
						display: flex;
						flex-direction: column;

						div {
							height: 220px;
							background: url("https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/other/no.feebf58.png") no-repeat center;
							display: inline-block;
						}

						.a {
							font-size: 20px;
							vertical-align: middle;
							color: #0088ff;
						}
					}

					.product_page {
						text-align: center;
						padding: 10px 0 30px;
					}

					.el-form-item {
						width: 100%;
						display: flex;
						flex-direction: row;
					}

					.formdesc {
						.el-form-item__content {
							width: 758px;
							padding: 8px 0;
							line-height: 25px !important;
						}
					}
				}

			}

		}
	}
</style>
